import React from 'react';

// Components
import {
  Layout,
  Card,
  Container,
  CardGroup,
  OrderProcess,
  NewsFeed,
  ServiceFeed,
} from '../../components';

// Data
import data from '../../data/pages/services.json';
import serviceList from '../../data/service-list';

const Services = () => {
  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        <ServiceFeed headerProps={{ title: "", text: "" }} />
        <OrderProcess />
        <NewsFeed />
      </Container>
    </Layout>
  );
};

export default Services;
